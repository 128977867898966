<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.CharacterCertificate}}</h5>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="form-group">
                                            <div class="row">

                                                <div *ngIf="roleId=='4' || roleId=='5'" class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="getCourses($any($event.target).value)"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
                          
                                                </div>
                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.Course}}: </label>
                                                    <select (change)="courceChange($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.Students}}: </label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        (change)="getUsersData($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of students" [value]='item.userid'>
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="certificate-container" id="printtable" *ngIf="receipt">
                                            <div class="certificate-header">
                                                <div class="logo1" style="width: 15%; text-align: center;">
                                                    <img src="../../../assets/img/logo1.jpg" alt="" width="70px">
                                                </div>
                                                <div style="width: 70%; text-align: center;">
                                                    <p style="margin-bottom:0">Shri Sai Shikshan Sanstha's</p>
                                                    <h2 style="color: #FD5845; font-size: 2.3em; margin-bottom:0">NIT
                                                        Polytechnic</h2>
                                                    <h4 style="color: #FD5845; font-size: 15px; font-weight: normal;">
                                                        All Programmes are NBA Accredited</h4>
                                                </div>
                                                <div class="logo2" style="width: 15%; text-align: center;">
                                                    <img src="../../../assets/img/logo2.jpg" alt="" width="70px">
                                                </div>
                                            </div>
                                            <div class="certificate-header" style="padding: 0;">
                                                <div class="logo1" style="width: 10%; text-align: center;"></div>
                                                <div style="width: 80%;">
                                                    <p style="font-size: 12px; text-align: center;">(An ISO 9001-2015 and 14001-2015 certified Institute)<br />(Approved by: AICTE, DTE Mumbal, Govt. of
                                                        Maharashtra and Affiliated to MSBTE Mumbai)</p>
                                                    <p style="margin-top: 8px; font-size: 12px;"><b>Campus :</b> Survey
                                                        No. 13/2. Mahurzari, Near Fetri, Katol Road, Nagpur-441
                                                        501<br /><b>Mobile :</b> 9545525450, 9545557700, 7972515445,
                                                        8007777170<br /> <b>Website :</b>
                                                        www.nitpoly.edu.in,&nbsp;&nbsp;<b>E-mail :</b>
                                                        principalnitpoly@nitpoly.edu.in<br /><b>Corporate Office :</b>
                                                        2nd Floor, Midas Heights, Central Bazar Road, Ramdaspeth,
                                                        Nagpur-10<br /><b>Ph.:</b> (0712) 2462268.</p>
                                                    <div
                                                        style="font-size: 1rem; margin-top: 10px; margin-bottom: 5px; color: #FD5845;">
                                                        <span style="float: left;">Principal: Prof. G, F.
                                                            Potbhare</span>
                                                        <span style="float: right;">Secretary: Salil Deshmukh</span>
                                                        <div style="clear: both;"></div>
                                                    </div>
                                                </div>
                                                <div class="logo1" style="width: 10%; text-align: center;"></div>
                                            </div>
                                            <hr style="margin-top: 0; margin-bottom:0; height: 5px; color: black; background-color: black;" />
                                            <div style="padding: 20px 60px;">

                                                <div style="float: left;">Ref. No.: 1234567</div>
                                                <div style="float: right;">Date: {{date | date:'mediumDate'}}</div>
                                                <div style="clear: both;"></div>

                                                <div style="padding: 30px">
                                                    <h2
                                                        style="text-decoration: underline; margin: 30px auto; text-align: center; font-size: 1.8em;">
                                                        CHARACTER CERTIFICATE</h2>
                                                    <p
                                                        style="line-height: 2.5; word-spacing: 6px; text-indent: 2.5rem;">
                                                        This is to certify that <b>{{usersData.name}}</b> was a bonafide
                                                        Student of this institute and has passed his <b>Diploma in
                                                            {{usersData.course_name}} in the examination</b> conducted
                                                        by Maharashtra State Board of Technical Education in the
                                                        <b>Academic Year {{usersData.year}}</b>.</p>
                                                    <p
                                                        style="line-height: 2.5; word-spacing: 6px; text-indent: 2.5rem; margin-top: 10px;">
                                                        His/Her character is good. I wish him/her all the best for his/her future
                                                        endeavour.</p>

                                                    <div style="float: right; margin-top: 50px;">
                                                        <p style="text-align: center; font-weight: 600;">
                                                            Principal<br />NIT Polytechnic, Nagpur</p>
                                                    </div>
                                                    <div style="clear: both;"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer text-center pb-2" *ngIf="receipt">
                                        <button class="btn btn-save btn-sm" (click)="convetToPDF()">download</button>
                                        &nbsp;
                                        <button class="btn btn-save btn-sm" (click)="print()">Print</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>